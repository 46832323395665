* {
  border: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto Mono', monospace;
  background: #2C2F33;
}

a {
  color: inherit;
  font-family: inherit;
  font-size: 44px;
  font-weight: bold;
  text-decoration: none;
}

.cuerpo{
  margin-top: 80px;
  color: white;
}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 550px) {

}

@media only screen and (max-width: 465px) {

}


