.techGrid{
    width: 60%;
    float: left;
    display: grid;
    vertical-align: middle;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
}

@media only screen and (max-width: 700px) {
    .techGrid{
        width: 100%;
    }
}

@media only screen and (max-width: 450px) {
    .techGrid{
        grid-template-columns: repeat(4, 1fr);
    }
}