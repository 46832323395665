.contenedorSocial{
    height: 130px;
    background-color: #25262B;
    border-radius: 35px;
    margin: 370px 27% 80px;
    box-shadow: 0 5px 10px 0 #00000060;
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: row;
    padding-left: 5%;
    padding-right: 2%;
}

.anclita{
    flex: 1;
    transition-duration: 0.5s;
}

.anclita img {
    max-height: 70px;
    max-width: 70px;
    width: 55%;
}

.anclita:hover {
    transform: scale(1.05);
}

@media only screen and (max-width: 800px) {
    .contenedorSocial{
        margin: 370px 10% 80px;
    }
}