.companyNamesGrid{
    display: flex;
    flex-wrap: wrap;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 30px;
    justify-content: center;
}

.companyNamesGrid p{
    margin-right: 20px;
    font-size: 1em;
    font-weight: bold;
}

#dd2{
    margin-top: 30px;
}

.pdHeader{
    height: 460px;
    width: 100%;
    background-color: #25262B;
    display: flex;
    margin-top: 40px;
    flex-direction: column;
}

.companiesPics{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 400px;
}



.inspoWhat{
    display: grid;
    height: 400px;
    background-color: #25262B;
    grid-template-columns: 1fr 0.2fr 0.2fr 1fr;
    column-gap: 40px;
    padding-left: 30px;
    padding-right: 30px;
}

.inspoWhat p{
    text-align: justify;
}

.inspoWhat img{
    max-height: 300px;
    margin-top: 30px;
    border-radius: 10px;
}

.imatgesRenders{
    margin-top: 30px;
    display: flex;
    width: auto;
    flex-direction: row;
    height: 300px;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    overflow: hidden;
}

.imatgesRenders img{
    max-height: 300px;
    margin-left: 40px;
    margin-right: 40px;
}

.sectionTitle{
    font-size: 2em;
    font-weight: bold;
    text-align: center !important;
    margin-top: 30px;
    margin-bottom: 20px;
}

.finalButtons{
    height: 400px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-left: 10%;
    margin-right: 10%;
}

.technologiesPD{
    height: 400px;
    background-color: #25262B;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
}

.techDescription{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 6%;
    margin-bottom: 6%;
    padding-left: 50px;
}


.techDescription p{
    text-align: justify;
}

#technologiesTitle{
    text-align: center;
    font-size: 3em;
    font-weight: bold;
    align-self: center;
}

#technologiesTitleMBL{
    display: none;
    text-align: center;
    font-size: 3em;
    font-weight: bold;
    align-self: center;
}

.companiesPics img{
    max-width: 90px;
    object-fit: contain;
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
    opacity: 0.3;
    transition-duration: 0.5s;
}

.companiesPics img:hover{
    opacity: 1;
}

#whatPD{
    font-size: 2em;
    font-weight: bold;
    margin-left: 40px;
    margin-top: 2%;
    margin-bottom: 20px;
}

.mobilePlaneImg{
    display: none;
    grid-template-columns: 1fr 1fr;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
}

.mobilePlaneImg img{
    justify-self: center;
}

.pdDescription{
    padding-left: 40px;
    padding-right: 40px;
    text-align: justify;
    margin-top: 20px;
}

@media only screen and (max-width: 953px) {
    .pdHeader{
        height: 100%;
        padding-bottom: 5%;
    }
    .technologiesPD{
        height: 100%;
    }
    .techDescription{
        font-size: 0.9em;
        margin-top: 5%;
    }
    .techDescription p{
      margin-bottom: 20px;
    }
    #technologiesTitle{
        font-size: 2em;
    }
}


@media only screen and (max-width: 1240px) {
    .inspoWhat{
        height: 100%;
        padding-bottom: 2%;
    }
}

@media only screen and (max-width: 1000px) {
    .inspoWhat{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .desktopImg{
        display: none;
    }
    .mobilePlaneImg{
        display: grid;
    }
}

@media only screen and (max-width: 450px) {
    .mobilePlaneImg{
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
    }
    .mobilePlaneImg img{
        height: 250px;
    }
}

@media only screen and (max-width: 785px) {
    .companiesPics img{
        max-width: 70px;
        margin: 10px;
    }
    .companiesPics{
        height: 100%;
        margin-bottom: 5%;
        margin-top: 5%;
    }
    .inspoWhat{
        padding-bottom: 5%;
    }
    .technologiesPD{
        display: flex;
        flex-direction: column;
    }
    #technologiesTitleMBL{
        display: flex;
        font-size: 2em;
        margin-top: 30px;
    }
    #technologiesTitle{
        display: none;
    }
    .techDescription{
       padding-left: 20px;
        padding-right: 20px;
    }
    .finalButtons{
        height: 300px;
        margin-top: 40px;
        margin-bottom: 40px;
        flex-direction: column;
    }
}

@media only screen and (max-width: 850px){
    .linksAutors span{
        opacity: 1;
    }
    .pdDescription{
        font-size: 0.9em;
    }
    #dd2{
        font-size: 0.9em;
    }
}