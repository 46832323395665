.elementTimeline img{
    height: 50px;
    width: 50px;
}

.imageTitle{
    height: 300px;
    display: flex;
    margin: 70px 20% 80px;
}

.myAtributtes{
    color: #65f4ad;
    font-style: italic;
}

.myName{
    font-size: 2em;
    margin-bottom: 10px;
}

.myDescription{
    margin-top: 30px;
    text-align: justify;
}
.imagenEvento{
    border-radius: 10px;
    padding: 5px;
    height: 70px !important;
    width: 70px !important;
}

.separator{
    height: 240px;
}

.separatorDot{
    height: 10px;
    width: 10px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #65f4ad;
    border-radius: 50%;
    display: inline-block;
}

.urls a{
    font-size: 1em;
}

.myInfo{
    float: right;
    margin-left: 50px;
}

.vertical-line{
    display: inline-block;
    border-left: 4px solid #65F4AD;
    height: 150px;
    margin-top: -5px;
}

.button-82-pushable{
    margin-top: 0 !important;
}
.downloadCV{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-items: center;
    margin-top: 10px;
    z-index: 10;
    margin-bottom: 50px;
}

.myImage{
    float: left;
    border: 4px solid #65f4ad;
    height: 300px;
    width: 300px;
    z-index: 1;
}

.myImage img{
    height: 300px;
    width: 300px;
    z-index: 5;
    transform: translateX(15px) translateY(-25px);
}

.currently{
    text-align: center;
    font-size: 1.3em;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 5%;
}

.currently a{
    transition-duration: 0.5s;
}

.currently a:hover{
    opacity: 0.5;
}

.currentlyLink{
    font-size: 1em;
    color: #65f4ad;
    font-weight: normal;
}

#description{
    margin-bottom: 10px;
}

.location{
    font-style: italic;
    color: #65f4ad;
}

.separatorLine{
    width: 40%;
    border-top: 3px solid #65f4ad;
    padding-top: 40px;
    margin-left: 30%;
    margin-right: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.separatorLine h1{
    margin: 0;
    text-align: center;
    margin-bottom: 70px;
}

.todayAndDot{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.todayAndDot p{
    text-align: center;
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 20px;
}

.greenDot {
    height: 30px;
    width: 30px;
    background-color: #65f4ad;
    border-radius: 50%;
    display: inline-block;
}


@media only screen and (max-width: 1000px) {
    .imageTitle{
        display: flex;
        height: 500px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 80px 30px 100px !important;
    }

    .myName{
        text-align: center;
    }
    .myAtributtes{
        text-align: center;
    }
    .myInfo{
        margin-top: 20px;
        margin-left: 0;
    }
    .currently{
        font-size: 1em;
        margin: 0px 30px 5%;
    }
}

@media only screen and (max-width: 850px) {
    .imageTitle{
        height: 550px;
    }
}

@media only screen and (max-width: 1590px) {
    .imageTitle{
        margin-bottom: 90px;
    }
    .currently{
        font-size: 1em;
        margin-top: 30px;
        margin-left: 30px;
        margin-right: 30px;
    }
}

@media only screen and (max-width: 1370px) {
    .imageTitle{
        margin-bottom: 150px;
    }
    .currently{
        margin-left: 20%;
        margin-right: 20%;
    }
}

@media only screen and (max-width: 1255px) {
    .imageTitle{
        margin-bottom: 240px;
    }
}

@media only screen and (max-width: 1160px) {
    .imageTitle{
        margin-bottom: 300px;
    }
}

@media only screen and (max-width: 1097px) {
    .imageTitle{
        margin-bottom: 350px;
    }
}

@media only screen and (max-width: 1034px) {
    .imageTitle{
        margin-bottom: 380px;
    }
}



@media only screen and (max-width: 540px) {
    .imageTitle{
        height: 800px;
        margin: 60px 10px 0px;
    }
    .currently{
        margin-left: 10px;
        margin-right: 10px;
    }
}

@media only screen and (max-width: 450px) {
    .imageTitle{
        height: 800px;
        margin: 60px 10px 60px;
    }
}


@media only screen and (max-width: 1169px) {
    .separatorLine p{
        text-align: start;
    }
    .todayAndDot{
        align-items: flex-start;
    }
    .todayAndDot p{
        margin-left: 1.5%;
    }
    .greenDot{
        margin-left: 3%;
    }
    .vertical-timeline-element-icon{
        margin-left: 11px !important;
    }
    .downloadCV{
        align-items: center;
        margin-left: 47px;
        margin-right: 5%;
        width: 93%;
        padding-bottom: 30px;
        padding-top: 30px;
        border-radius: 15px;
        margin-top: -15px;
    }
    .vertical-line{
        display: none;
    }
}

@media only screen and (max-width: 1040px) {
    .downloadCV {
        margin-left: 44px;
    }
}

@media only screen and (max-width: 880px) {
    .downloadCV {
        margin-left: 40px;
    }
}

@media only screen and (max-width: 670px) {
    .downloadCV {
        margin-left: 35px;
    }
}

@media only screen and (max-width: 500px) {
    .downloadCV {
        margin-left: 31px;
    }
}

@media only screen and (max-width: 390px) {
    .downloadCV {
        margin-left: 28px;
    }
}

@media only screen and (max-width: 450px) {
    .todayAndDot p{
        margin-left: 1.75%;
    }
    .greenDot{
        margin-left: 3.5%;
    }

}