
.projectHeader{
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 600px;
}

.projectHeaderImage{
    height: 100%;
    width: 100%;
    border-radius: 15px 0 0 15px;
    overflow: hidden;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.projectHeaderImage img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.projectStack{
    display: grid;
    grid-template-columns: 120px 120px 120px;
    row-gap: 25px;
}


.projectHeaderDetails{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin-left: 10%;
}

#pName{
    font-size: 3.5em;
    font-weight: bold;
    margin-bottom: 30px;
    margin-left: 20px;
}

#pYear{
    font-size: 2.5em;
    margin-left: 20px;
    margin-bottom: 70px;
}

.lineSeparadora{
    width: 88%;
    height: 5px;
    margin-top: 3%;
    margin-left: 6%;
    margin-right: 6%;
    background-color: #65f4ad;
}

.projectStackMBL{
    display: none;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
}

.projectStackMBL .item{
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
}


@media only screen and (max-width: 1000px) {
    .projectHeader{
        grid-template-columns: 0.8fr 1fr;
    }
}

@media only screen and (max-width: 850px) {
    .projectHeader{
        display: flex;
        flex-direction: column;
        height: 900px;
    }
    .projectHeaderDetails{
        width: 80%;
        margin-right: 10%;
        align-items: center;
    }
    .projectStackMBL{
        display: flex;
    }
    .projectStack{
        display: none;
    }
    #pYear{
        margin-bottom: 30px;
        margin-left: 0;

    }
    #pName{
        margin-bottom: 10px;
        margin-top: 20px;
        margin-left: 0;
        font-size: 2.8em;
    }
    .projectHeaderImage{
        height: 60%;
        border-radius: 15px;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        margin-bottom: 40px;
    }

    .projectHeaderImage img{
        width: 100%;
        height: 100%;
    }
}