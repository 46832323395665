.firstHeader {
    background-color: #25262B;
    height: 670px;
    padding-top: 60px;
    margin-top: 40px;
    padding-bottom: 60px;
}

.centerDivSallefy{
    margin-left: 20px;
    margin-right: 20px;
}

.centerDivSallefy p {
    margin-bottom: 30px;
}

.sallefyGrid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-right: 50px;
    margin-left: 50px;
    margin-bottom: 50px;
    text-align: justify;
}

.sallefyGrid a{
    font-size: 1em;
    font-weight: normal;
    transition-duration: 0.5s;
}

.sallefyGrid a:hover{
    color: #65f4ad;
}

#dd1{
    text-align: center;
    margin-left: 100px;
    margin-right: 100px;
    margin-bottom: 30px;
}

#dd2{
    text-align: center;
    margin-bottom: 30px;
}

.linksAutors{
    font-size: 0.7em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.linksAutors a{
    font-size: 1.5em;
   transition-duration: 0.5s;
}

.linksAutors a:hover{
    color: #65f4ad;
}

#projTitle{
    font-size: 1.5em;
    font-weight: bold;
    margin-left: 30px;
    margin-bottom: 80px;
}

.dotAutors {
    height: 5px;
    width: 5px;
    margin-left: 30px;
    margin-right: 30px;
    background-color: white;
    border-radius: 50%;
    display: inline-block;
}

.seccio2{
    height: 500px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.designsMade{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-weight: bold;
    font-size: 2em;
}

.designsMade p{
    margin-left: 30px;
    margin-right: 50px;
}

.functionalityGrid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 30px;
    height: 100%;
    padding-bottom: 50px;
    padding-top: 50px;
    padding-right: 50px;
}

.functionalityGrid p{
    text-align: start;
    overflow-wrap: break-word;
    white-space: nowrap;
    margin: auto auto auto 0;
}

.video1{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 60px;
    padding-left: 30px;
}

.video2{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 50px;
    padding-right: 30px;
}

.finalDiv{
    height: 250px;
    background-color: #25262B;
    margin: 10% 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 15px;
}
.finalDiv p {
    margin-bottom: 30px;
}

.videoInfo{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.videoInfo p{
    margin-bottom: 30px;
    margin-left: 30px;
    margin-right: 30px;
    text-align: justify;
}

.videoInfoMBL{
    display: none;
    flex-direction: column;
    justify-content: center;
}

.videoInfoMBL p{
    margin-bottom: 30px;
    margin-left: 30px;
    margin-right: 30px;
    text-align: justify;
}

.videoCenter{
    display: flex;
    justify-content: center;
    align-items: center;
}

.resp-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
}

.resp-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

#finalP{
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
    margin-top: 40px;
}

@media only screen and (max-width: 1246px) {
    .firstHeader{
        height: 100%;
        margin-bottom: 2%;
    }
}

@media only screen and (max-width: 850px) {
    #projTitle{
        margin-bottom: 10px;
    }
    .sallefyGrid{
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }
    .centerDivSallefy{
        margin: 0;
    }
    #dd1{
        text-align: justify;
        margin-left: 50px;
        margin-right: 50px;
    }
    .sallefyGrid p{
        text-align: justify;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .linksAutors a{
        font-size: 1.2em !important;
    }
    .firstHeader{
        font-size: 0.9em;
    }
    .seccio2{
        display: flex;
        flex-direction: column;
    }
    .designsMade{
        margin-left: 5%;
        margin-right: 5%;
        text-align: center;
    }
    .functionalityGrid{
        margin-left: 3%;
        margin-right: 3%;
        width: 94%;
        grid-template-columns: 1fr 1fr;
        row-gap: 20px;
    }
    .functionalityGrid p{
        align-self: center;
        text-align: center;
        overflow-wrap: break-word;
        word-wrap: break-word;
        white-space: unset;
        hyphens: auto;
        margin: 0;
    }
    .video1{
        display: flex;
        flex-direction: column;
        padding-left: 0;
    }
    .video2{
        display: flex;
        flex-direction: column;
        padding-right: 0;
    }
    .video2 .videoInfoMBL{
        display: flex;
    }
    .video2 .videoInfo{
        display: none;
    }
    .videoCenter{
        position: relative;
        overflow: hidden;
        padding-top: 56.25%;
        margin-left: 5%;
        margin-right: 5%;
    }
    .videoCenter iframe{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
    .finalDiv{
        height: 100%;
        padding-top: 4%;
        padding-bottom: 5%;
        margin-left: 5%;
        margin-right: 5%;
    }

}

@media only screen and (max-width: 950px) {
    .seccio2{
        height: 100%;
        margin-bottom: 2%;
        grid-template-columns: 0.5fr 1fr;
    }
    .designsMade{
        font-size: 1em;
    }
    .designsMade p{
        margin-left: 20px;
        margin-right: 20px;
    }
}

@media only screen and (max-width: 590px) {
    .designsMade{
        font-size: 0.9em;
    }
    .functionalityGrid p{
        font-size: 0.8em;
    }
}


@media only screen and (max-width: 540px) {
    .sallefyGrid{
        margin-left: 20px;
        margin-right: 20px;
    }
    #dd1{
        margin-left: 20px;
        margin-right: 20px;
    }
}