.item img{
    width: 80% !important;
    max-height: 70px;
    object-fit: contain;
}

.name{
    padding-bottom: 7px;
    font-size: 0.75em;
    font-weight: bold;
}

.item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 110px;
    text-align: center;
}

.imageItemTech{
    transition-duration: 0.5s !important;
}

.imageItemTech:hover{
    transform: scale(1.15) !important;
}

@media only screen and (max-width: 450px) {
    .item img{
        max-height: 70px !important;
    }
}

@media only screen and (max-width: 620px) {
    .item img{
        max-height: 70px !important;
    }
    .name{
        padding-bottom: 5px;
        font-size: 0.6em;
    }
}