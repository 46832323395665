.descriptionBox{
    border-radius:  0 0 5px 5px;
    height: fit-content;
    padding-top: 50px;
    padding-bottom: 30px;
    width: 100%;
    background-color: #25262B;
    z-index: 5;
    text-align: justify;
}

.descriptionBox p{
    padding-left: 15px;
    padding-right: 15px;
    font-size: 10px;
}

.clickable-section{
    box-shadow: 0 3px 3px 3px #00000060;
    border-radius: 5px;
    height: fit-content;
    margin-bottom: 20px;
}

.projectItemBody{
    width: 40%;
    min-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
}

.clickable-section a {
    width: 100%;
    height: 300px;
    overflow: hidden;
    display: block;
}

.clickable-section a img{
    width: 100%;
    max-height: 350px;
    border-radius: 5px 5px 0 0;
    object-fit: cover;
    transition-duration: 0.5s;
    margin-bottom: 20px;
    cursor: pointer;
}
.clickable-section a img:hover {
    transform: scale(1.02);
}

.projectItemBody p{
    font-size: 0.8em;
    font-weight: bold;
    margin-bottom: 20px;
}

.learnButton{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.learnButton .button-82-pushable{
    width: 100%;
}

@media only screen and (max-width: 916px) {
    .languagesStack{
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .languagesStack .item img{
        width: 80% !important;
        max-height: 50px;
        object-fit: contain;
    }
    .learnButton{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 70px;
        padding-right: 5%;
        margin-top: 40px;
    }
    .learnButton .button-82-pushable{
        width: 120%;
    }

    .learnButton a{
        font-size: 1em !important;
    }
}

@media only screen and (max-width: 430px) {
    .projectItemBody{
        width: 40%;
        min-width: 300px;
    }
    .clickable-section a {
        height: 170px;
    }
    .clickable-section a img{
        max-height: 200px;
    }
}
