.phoneGallery{
    height: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.phoneGallery p {
    text-align: center;
    font-size: 1.2em;
    margin-top: 30px;
    font-weight: bold;
    margin-bottom: 70px;
}

.galleyContainer{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 250px;
    align-items: center;
}

.phone{
    width: 100%;
    pointer-events:auto;
}

.phoneFrame{
    z-index: 9;
    width: 100%;
    pointer-events: none;
    object-fit: contain;
    position: absolute;
}

.phoneFrame img{
    width: 280px;
    transform: translateY(-35px) translateX(-12px);
}

.imageItem{
    min-width: 100%;
    background-color: #65f4ad;
}


.galleryButtons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.imageButton{
    width: 60px;
    height: 60px;
    cursor: pointer;
    transition-duration: 0.2s;
}

.imageButton:hover{
    transform: scale(1.1);
}

.buttonR {
    margin-left: 40px;
    background-image: url("../../assets/images/icons/arrowRight.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;
}

.buttonR:active{
    transform: scale(0.9);
}

.buttonL{
    margin-right: 40px;
    background-image: url("../../assets/images/icons/arrowLeft.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;
}

.buttonL:active{
    transform: scale(0.9);
}

#phoneTitle{
    margin-left: 5%;
    margin-right: 5%;
}

#currentPosition{
    color: #65f4ad;
    font-size: 0.8em;
    font-weight: bold;
}

@media only screen and (max-width: 850px) {
    .imageButton{
        display: none;
    }
    .phoneGallery{
        height: 100%;
    }
}
