
.headerInfo{
    background-color: #25262B;
    position: relative;
    margin-top: 180px;
    height: 420px;
    padding-top: 250px;
    width: 100%;
}

.headerInfo p{
    margin-left: 10%;
    margin-right: 10%;
    font-size: 1.2em;
    text-align: center;
    font-weight: bold;
}

.workList {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 70px;
    justify-content: space-around;
}

.languagesStack{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.languagesStack .item{
    margin-right: 15px;
}

.mainProjectBody{
    height: 650px;
    display: flex;
    margin: 2% 5% 1% 5%;
}
.mainProjectBody p{
    font-size: 1.7em;
    font-weight: bold;
    margin-bottom: 30px;
}

.button-82-pushable{
    margin-left: 2%;
    width: 100%;
}

.button-82-pushable a{
    font-size: 1.2em;
}

.learnButton{
    margin-top: 30px;
}

.preEndDiv{
    display: flex;
    height: 280px;
    margin: 7% 10%;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
    flex-direction: column;
    background-color: #25262B;
    border-radius: 25px;
    align-items: center;
    justify-items: center;
    justify-content: center;
    text-align: center;
}

.preEndDiv img{
   height: 70px;
    margin-bottom: 5%;
}


.noteWorthy{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 9%;
    margin-right: 9%;
    margin-top: 3%;
}

.noteWorthy p{
    text-align: center;
}

.noteWorthyProjects{
    font-size: 1.8em;
    font-weight: bold;
}

.title{
    font-size: 1.8em;
    font-weight: bold;
    text-align: center;
    margin-top: 100px;
}

.repoGrid{
    margin-top: 40px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.projectTitle{
    font-size: 1.5em !important;
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
}

.developer-pages {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 30px;
}

.developer-pill {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #25262B;
    border-radius: 10px;
    height: 150px;
    width: 30%;
    padding: 10px;
    margin: 10px;
    transition: transform 0.3s ease;
    cursor: pointer;
    font-size: 1.5em;
    font-weight: bold;
    box-shadow: 0 3px 3px 3px #00000060;
}

.developer-pill img {
    width: 50px;
    height: auto;
    margin-right: 40px;
}

.developer-pill:hover {
    transform: scale(1.02);
}


@media only screen and (max-width: 1000px) {
    .headerInfo{
        height: 380px;
        padding-top: 220px;
    }
    .headerInfo p{
        margin-left: 10%;
        margin-right: 10%;
        font-size: 1.6vw;
    }
}

@media only screen and (max-width: 1040px) {
    .developer-pill img {
        margin-right: 20px;
    }
    .developer-pill {
        height: 110px;
        font-size: 1.2em;
    }
}

@media only screen and (max-width: 850px) {
    .developer-pill img {
        width: 40px;
        margin-right: 10px;
    }
    .developer-pill {
        height: 110px;
        font-size: 1.1em;
    }
}

@media only screen and (max-width: 740px) {
    .developer-pages {
        flex-direction: column;
    }
    .developer-pill img {
        width: 60px;
        margin-right: 20px;
    }
    .developer-pill {
        height: 110px;
        width: 80%;
        font-size: 1.3em;
    }
}

@media only screen and (max-width: 700px) {
    .headerInfo p{
        margin-left: 6%;
        margin-right: 6%;
        font-size: 2vw;
    }
}

@media only screen and (max-width: 500px) {
    .headerInfo{
        padding-top: 180px;
    }
    .headerInfo p{
        font-size: 3.5vw;
    }
}


@media only screen and (max-width: 800px) {
    .preEndDiv{
        margin: 7% 10%;
    }
}

@media only screen and (max-width: 600px) {
    .preEndDiv{
        margin: 7% 10%;
        font-size: 0.8em;
        text-align: justify;
    }
    .preEndDiv img{
        height: 50px;
        margin-bottom: 5%;
    }
}





