.techStack{
    background-color: #25262B;
    height: 800px;
    padding-top: 150px;
    padding-left: 50px;
    padding-right: 50px;
    overflow: hidden;
}

#stackTitle{
    font-weight: bold;
    font-size: 1.8em;
    padding-bottom: 10px;
}

#stackDescription{
    padding-bottom: 30px;
    font-size: 0.9em;
    font-style: italic;
    opacity: 0.8;
    margin-right: 40%;
    text-align: justify;
}

.techImage{
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
    transform: translateY(-28%) !important;
}

.imatgeTechStacking{
    width: 100%;
    height: 50%;
    max-height: 500px;
    max-width: 624px;
}

.react-tabs__tab--selected{
    color: #65f4ad !important;
    background: transparent !important;
    border-color: transparent !important;
    font-weight: bold;
}

.separatorPunto{
    height: 6px;
    width: 6px;
    background-color: white;
    border-radius: 50%;
    display: inline-block;
    margin-left: 15px;
    margin-right: 15px;
}

#stackDot{

}

.tabListNames{
    width: 60%;
    color: white;
    text-align: center;
    font-weight: normal;
    font-size: 0.9em;
}

.panelStack{
    margin-top: 50px;
}

@media only screen and (max-width: 450px) {
    .techStack{
        height: 970px;
    }
}

@media only screen and (max-width: 800px) {
    .techImage{
        transform: translateY(0) !important;
    }
}

@media only screen and (max-width: 1000px) {
    .techImage{
        transform: translateY(-5%) !important;
    }
}

@media only screen and (max-width: 700px) {
    .techImage{
        display: none;
    }
    .tabListNames{
        width: 100%;
    }
    .panelStack{
        width: 100%;
    }
    #stackDescription{
        margin-right: 0 !important;
        width: 100%;
    }
}

@media only screen and (max-width: 447px) {
    .separatorPunto{
        margin-left: 5px;
        margin-right: 5px;
    }
}

@media only screen and (max-width: 447px) {
    .separatorPunto{
        opacity: 0;
    }
}

@media only screen and (max-width: 400px) {
    #stackDot{
        display: none;
    }
}