input {
	outline: none;
	border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus, input:focus {
  border-color: transparent !important;
}

input::-webkit-input-placeholder { color: #bdbdd3;}
input:-moz-placeholder { color: #bdbdd3;}
input::-moz-placeholder { color: #bdbdd3;}
input:-ms-input-placeholder { color: #bdbdd3;}

textarea::-webkit-input-placeholder { color: #bdbdd3;}
textarea:-moz-placeholder { color: #bdbdd3;}
textarea::-moz-placeholder { color: #bdbdd3;}
textarea:-ms-input-placeholder { color: #bdbdd3;}

button {
	outline: none !important;
	border: none;
	background: transparent;
}

button:hover {
	cursor: pointer;
}

.container {
	max-width: 1200px;
}

.wrap-contact100 {
  width: 550px;
  background: transparent;
  padding: 50px 0px 160px 0px;
}

.contact100-form {
  width: 100%;
}

.wrap-input100 {
  width: 100%;
  background-color: #fff;
  border-radius: 31px;
  margin-bottom: 16px;
  position: relative;
  z-index: 1;
}

.input100 {
  position: relative;
  display: block;
  width: 100%;
  background: #fff;
  border-radius: 31px;
  font-size: 18px;
  color: black;
  font-weight: normal;
  font-family: 'Roboto Mono', monospace;
  line-height: 1.2;
}

input.input100 {
  height: 62px;
  padding: 0 35px 0 35px;
}


textarea.input100 {
  min-height: 169px;
  padding: 19px 35px 0 35px;
}

.focus-input100 {
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 31px;
  background-color: #fff;
  pointer-events: none;
  
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input100:focus + .focus-input100 {
  width: calc(100% + 20px);
}

.container-contact100-form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 10px;
}

.contact100-form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 150px;
  height: 62px;
  background-color: transparent;
  border-radius: 31px;
  font-family: 'Roboto Mono', monospace;
  font-size: 20px;
  color: black;
  font-weight: bold;
  line-height: 1.2;
  text-transform: uppercase;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  position: relative;
  z-index: 1;
}

.contact100-form-btn::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 31px;
  background-color: #65f4ad;
  pointer-events: none;
  
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.contact100-form-btn:hover:before {
  width: calc(100% + 20px);
}

.contact100-form-btn:active:before{
  width: calc(100% - 2px);
  height: calc(100% - 2px);
}

.validate-input {
  position: relative;
}



