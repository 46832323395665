#dd2{
    margin-top: 30px;
}

.p-header-section{
    height: 420px;
    width: 100%;
    background-color: #25262B;
    display: flex;
    margin-top: 40px;
    flex-direction: column;
}

.companiesPics{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 400px;
}


.api-section {
    display: flex;
    height: fit-content;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #25262B;
}

.api-section p{
    text-align: justify;
    margin-left: 15%;
    margin-right: 15%;
}

.api-section img{
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 30px;
    border-radius: 10px;
    margin-bottom: 50px;
}

.sectionTitle{
    font-size: 2em;
    font-weight: bold;
    text-align: center !important;
    margin-top: 30px;
    margin-bottom: 20px;
}

.finalButtons{
    height: 400px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-left: 10%;
    margin-right: 10%;
}

.technologiesPD{
    height: 400px;
    background-color: #25262B;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
}

.techDescription{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 6%;
    margin-bottom: 6%;
    padding-left: 50px;
}


.techDescription p{
    text-align: justify;
}

#technologiesTitle{
    text-align: center;
    font-size: 3em;
    font-weight: bold;
    align-self: center;
}

#technologiesTitleMBL{
    display: none;
    text-align: center;
    font-size: 3em;
    font-weight: bold;
    align-self: center;
}

#whatPD{
    font-size: 2em;
    font-weight: bold;
    margin-left: 40px;
    margin-top: 2%;
    margin-bottom: 20px;
}

.pdDescription{
    padding-left: 40px;
    padding-right: 40px;
    text-align: justify;
    margin-top: 20px;
}

@media only screen and (max-width: 953px) {
    .p-header-section{
        height: 100%;
        padding-bottom: 5%;
    }
    .technologiesPD{
        height: 100%;
    }
    .techDescription{
        font-size: 0.9em;
        margin-top: 5%;
    }
    .techDescription p{
      margin-bottom: 20px;
    }
    #technologiesTitle{
        font-size: 2em;
    }
}


@media only screen and (max-width: 1240px) {
    .api-section{
        height: 100%;
        padding-bottom: 2%;
    }
}


@media only screen and (max-width: 450px) {

}

@media only screen and (max-width: 785px) {
    .inspoWhat{
        padding-bottom: 5%;
    }
    .technologiesPD{
        display: flex;
        flex-direction: column;
    }
    #technologiesTitleMBL{
        display: flex;
        font-size: 2em;
        margin-top: 30px;
    }
    #technologiesTitle{
        display: none;
    }
    .techDescription{
       padding-left: 20px;
        padding-right: 20px;
    }
    .finalButtons{
        height: 300px;
        margin-top: 40px;
        margin-bottom: 40px;
        flex-direction: column;
    }
}

@media only screen and (max-width: 850px){
    .api-section p{
        font-size: 0.9em;
        margin-left: 5%;
        margin-right: 5%;
    }
    .sectionTitle{
        font-size: 2em !important;
    }
    .api-section img{
        width: 90%;
        margin-left: 0;
        margin-right: 0;
    }
    .linksAutors span{
        opacity: 1;
    }
    .pdDescription{
        font-size: 0.9em;
    }
    #dd2{
        font-size: 0.9em;
    }
}