.cartulina{
    flex-grow: 1;
    flex-basis: 0;
    background-color: #2A2E32;
    border-radius: 17px;
    height: 170px;
    max-width: 20%;
    box-shadow: 3px -3px 6px #00000032, -3px 3px 6px #00000032;
    padding: 10px 10px 10px 10px;
    transition-duration: 0.5s;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.cartulina:hover {
    box-shadow: 6px -6px 10px #00000032, -6px 6px 10px #00000032;
    transform: scale(1.02);
}

.cartulina img{
    min-width: 40px;
    height: 40px;
    margin-top: 2%;
    margin-bottom: 5%;
}

.cartulinaTitle{
    text-align: center;
    font-weight: bold;
    color: #65F4AD;
    margin-bottom: 10px;
}

.pilotes{
    opacity: 0.5;
}

.additional .pilotes{
    margin-bottom: 20px;
}

@media only screen and (max-width: 1200px) {
    .cartulina{
        height: 180px;
    }
    .pilotes {
        font-size: 0.7em;
    }
}

@media only screen and (max-width: 550px) {
    .cartulina{
        height: 160px;
        max-width: 25%;
    }
    .pilotes {
        font-size: 0.6em;
    }
}

@media only screen and (max-width: 465px) {
    .cartulina{
        height: 180px;
        max-width: 25%;
    }
    .cartulinaTitle{
        margin-top: 10px;
        font-size: 0.7em;
    }
}