.button-82-pushable {
    width: fit-content;
    position: relative;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
    margin-top: 100px;
    margin-left: 30%;
    transition: filter 250ms;
    font-size: 1.5em;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.button-82-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: #26292C;
    will-change: transform;
    transform: translateY(2px);
    transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
}

.button-82-edge {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: #6AC096;
}

.button-82-front {
    display: block;
    position: relative;
    padding: 12px 27px;
    border-radius: 12px;
    font-size: 1rem;
    font-family: 'Roboto Mono', monospace;
    font-weight: bold;
    color: black;
    background: hsl(150, 87%, 68%);
    will-change: transform;
    transform: translateY(-4px);
    transition:
            transform
            600ms
            cubic-bezier(.3, .7, .4, 1);
}


.button-82-pushable:hover .button-82-front {
    transform: translateY(-6px);
    transition:
            transform
            250ms
            cubic-bezier(.3, .7, .4, 1.5);
}

.button-82-pushable:active .button-82-front {
    transform: translateY(-2px);
    transition: transform 34ms;
}

.button-82-pushable:hover .button-82-shadow {
    transform: translateY(4px);
    transition:
            transform
            250ms
            cubic-bezier(.3, .7, .4, 1.5);
}

.button-82-pushable:active .button-82-shadow {
    transform: translateY(1px);
    transition: transform 34ms;
}

.button-82-pushable:focus:not(:focus-visible) {
    outline: none;
}
