.titleSection{
    height: 230px;
    width: 100%;
    border-bottom-left-radius:80%;
    border-bottom-right-radius:80%;
    color: white;
    background-color: #2c2f33;
    border: none;
    position: absolute;
    top: 80px;
    left: 0;
    z-index: 5;
    box-shadow: 0 -10px 0 #2c2f33, 0 5px 10px #000000;
    align-content: center;
    text-align: center;
    padding-top: 50px;
}

.titleSection p{
    font-family: 'Roboto Mono', monospace;
    font-weight: bold;
    font-size: 2.5em;
}

@media only screen and (max-width: 650px) {
    .titleSection{
        top: 60px;
        height: 190px;
        border-bottom-left-radius:20%;
        border-bottom-right-radius:20%;
    }
}