.getInTouch{
    text-align: center;
    font-size: 2.3em;
    font-weight: bold;
    margin-bottom: 2%;
    margin-top: 5%;
}

.touchText{
    margin-left: 30%;
    margin-right: 30%;
    font-size: 1em;
    text-align: center;
    margin-bottom: 4%;
}

.botong{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 8%;
}
:root {
    --toastify-color-success: #65f4ad !important;
}

.Toastify__progress-bar--success{
    background: #65f4ad !important;
}

.validate-form{
    width: 50% !important;
    margin-left: 25%;
    margin-right: 25%;
    margin-bottom: 4%;
}

@media only screen and (max-width: 800px) {
    .validate-form{
        width: 80% !important;
        margin-top: 30px;
        margin-left: 10%;
        margin-right: 10%;
    }

}