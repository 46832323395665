.separatorCards{
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: row;
    align-content: center;
    justify-content: space-around;
    z-index: 2;
    top: 75px;
    margin-top: -20px;
}






