.whyDyv{
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 0.5fr 1fr;
    height: 350px;
    background-color: #25262B;
    padding-top: 30px;
}

.downloadDyv{
    background-color: #25262B;
    height: 400px;
}
.downl{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.downloadDyv{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height: 300px;
}

.whereNowDyv{
    height: 300px;
    display: grid;
    margin-top: 60px;
    grid-template-columns: 1fr 1fr;
}

#titleDyv{
    font-size: 2em;
    margin-top: 20px;
    margin-left: 80px;
    font-weight: bold;
}

#whybodyLeft{
    text-align: justify;
    margin-left: 80px;
    margin-right: 20px;
}

#whybodyRight{
    text-align: justify;
    margin-right: 80px;
    margin-left: 20px;
}

#onlyAndroid{
    font-size: 2em;
    font-weight: bold;
    text-align: center;
}


.downloadButtons{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
    padding-top: 40px;
}

.downloadButtons a{
    width: 20%;
    padding-right: 10px;
}

.downloadButtons a img{
    width: 100%;
}

.downloadDescription{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.explanation{
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: justify;
}
.explanation p {
    margin-bottom: 20px;
}

.explanation a{
    font-size: 1em;
    font-weight: bold;
}

.explanation a:hover{
    color: #65F4AD;
}

#whereIs{
    text-align: end;
    margin-right: 20%;
    font-size: 2em;
    margin-top: 20px;
    font-weight: bold;
}

#whereIsMVL{
    display: none;
    text-align: center;
    margin-right: 5%;
    margin-left: 5%;
    font-size: 2em;
    font-weight: bold;
}


.srcCodeDyv{
    display: flex;
    font-size: 1.8em;
    font-weight: bold;
    padding-right: 40px;
    padding-left: 40px;
    background-color: #25262B;
    height: 200px;
    margin: 5% 20% 10%;
    text-align: center;
    border-radius: 15px;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.srcCodeDyv a{
    color: #65f4ad;
    font-size: 1.1em !important;
    transition-duration: 0.5s !important;
}

.srcCodeDyv a:hover{
    opacity: 0.6 !important;
}


@media only screen and (max-width: 1060px) {
    .whyDyv{
        height: 100%;
        padding-bottom: 4%;
    }
    .downloadDyv{
        height: 100%;
        display: flex;
        flex-direction: column;
        text-align: justify;
        padding: 4%;
    }
    .downloadButtons a{
        width: 60%;
        max-width: 300px;
        margin-top: -20px;
    }
    .whereNowDyv{
        height: 100%;
        padding-bottom: 4%;
    }
    .srcCodeDyv{
        height: 100%;
        margin: 5%;
        font-size: 1.3em;
        padding-top: 50px;
        padding-bottom: 50px;
    }

}

@media only screen and (max-width: 630px) {
    .whyDyv{
        display: flex;
        flex-direction: column;
    }
    .whyDyv p{
        margin: 5px 30px !important;
    }
    .whereNowDyv{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    #whereIsMVL{
        display: flex;
    }
    #whereIs{
        display: none;
    }
    .explanation{
        margin-left: 4%;
        margin-right: 4%;
        margin-top: 30px;
    }
}

@media only screen and (max-width: 500px) {
    .whyDyv p{
        font-size: 0.9em;
    }
    .downloadDyv{
        font-size: 0.9em;
    }
}

