.repoCard{
    height: 280px;
    width: 330px;
    border-radius: 5px;
    background-color: #25262B;
    box-shadow: 0 4px 14px 0 #00000080;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 20px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    position:relative;
    transition-duration: 0.5s;
    cursor: pointer;
}


.repoCard:hover{
    transform: scale(1.02);
}

.repoCard img{
    max-height: 40px;
}

.repoDescription{
    opacity: 0.8;
    font-size: 0.8em;
    text-align: start !important;
}

.repoTitle{
    margin-top: 15px;
    font-size: 1.3em;
    font-weight: bold;
    margin-bottom: 15px;
}

.filaLlenguatges{
    position: absolute;
    bottom: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 0.9em;
}

.filaLlenguatges{
    opacity: 0.4;
}

.openIn{
    height: 40px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.openIn img{
    height: 25px;
    width: 25px;
    opacity: 0.5;
}


@media only screen and (max-width: 880px) {
    .repoCard{
        height: 220px;
        width: 250px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .repoDescription{
        font-size: 0.6em;
    }
    .filaLlenguatges{
        font-size: 0.8em;
    }
}

@media only screen and (max-width: 685px) {
    .repoCard{
        height: 185px;
        width: 200px;
        padding-left: 5px;
        padding-right: 5px;
    }
    .repoTitle{
        font-size: 1em;
        margin-bottom: 5px;
    }
    .repoDescription{
        font-size: 0.5em;
    }
    .filaLlenguatges{
        font-size: 0.6em;
    }
    .repoCard img{
        max-height: 20px;
    }
    .openIn img{
        height: 15px;
        width: 15px;
        opacity: 0.5;
    }
}

@media only screen and (max-width: 560px) {
    .repoCard{
        height: 195px;
        width: 40%;
    }
    .repoTitle{
        font-size: 0.9em;
    }
}

