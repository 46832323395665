.kofuIntro{
    height: auto;
    background-color: #25262B;
    margin-top: 40px;
    padding-bottom: 5%;
    padding-top: 30px;
}

#kofuTitle{
    margin-left: 40px;
    font-size: 2em;
    font-weight: bold;
}

.centerGridKofu{
    margin-left: 20px;
    margin-right: 20px;
}

.gridKofu{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 20px 40px 0;
    text-align: justify;
}

.howBuilt{
    height: 450px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px 30px;
}

.builtColumn{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-content: center;
}

.howBuilt p {
    font-size: 1em;
    text-align: justify;
}

.linksAutors p{
    font-size: 1.6em;
    font-weight: bold;
}
.linksAutors a{
    text-align: center;
}

#howBuiltTitle{
    font-size: 2em;
    font-weight: bold;
    margin-left: 10%;
    align-self: center;
    margin-bottom: 20%;
}

.videoGridKofu{
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.videoGridKofu iframe{
    margin: 10px;

}

.videoGrid{
    margin: 40px 5%;
}

.imatgesDescripcio{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-right: 15%;
    margin-left: 15%;
    margin-bottom: 4%;
}

.kofuIntro img{
    margin-top: 60px;
    width: 100%;
}

.finaldivKofu{
    height: 350px;
    background-color: #25262B;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 4%;
    margin-bottom: 10%;
    padding-left: 10%;
    padding-right: 10%;
}

#videoDeveloped{
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
}
#videoDevelopedSubtitle{
    font-size: 1em;
    text-align: center;
    font-weight: normal;
    margin-bottom: 30px;
}

#latestNews{
    font-weight: bold;
    font-size: 1em;
    text-align: center;
}

#latestNewsTitle{
    font-weight: bold;
    font-size: 1.6em;
    text-align: center;
}

@media only screen and (max-width: 2890px) {
    .videoGridKofu{
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
    }
}

@media only screen and (max-width: 2170px) {
    .videoGridKofu{
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
    }
}

@media only screen and (max-width: 1930px) {
    .videoGridKofu iframe{
        width: 400px;
        height: 250px;
    }
}

@media only screen and (max-width: 1560px) {
    .videoGridKofu{
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
    .videoGridKofu iframe{
        width: 350px;
        height: 200px;
    }
}

@media only screen and (max-width: 1000px) {
    .gridKofu{
        font-size: 0.7em;
    }
    .imatgesDescripcio{
        margin-right: 20%;
        margin-left: 20%;
    }
    .kofuIntro img{
        margin-top: 20px;
    }
    .howBuilt p {
        font-size: 0.8em;
    }
    .howBuilt{
        height: 450px;
        display: grid;
        grid-template-columns: 0.6fr 1fr;
        padding: 20px 30px;
    }
    #howBuiltTitle{
        margin-right: 30px;
        font-size: 1.3em;
    }
    #videoDeveloped{
        font-size: 1.3em;
    }
    #videoDevelopedSubtitle{
        font-size: 0.8em;
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 850px) {
    .gridKofu{
        display: flex;
        flex-direction: column;
    }
    .linksAutors span{
        opacity: 0;
    }
    .linksAutors a{
        font-size: 0.9em;
    }
    .linksAutors p{
        font-size: 0.9em;
    }
    .dotAutors {
        margin-left: 15px;
        margin-right: 15px;
    }
    .centerGridKofu{
        margin: 20px 0;
    }
    .centerGridKofu img{
        margin-left: 10%;
        margin-right: 10%;
        width: 80%;
    }
    .imatgesDescripcio{
        margin-right: 10%;
        margin-left: 10%;
        width: 80%;
        margin-top: 30px;
    }

    .imatgesDescripcio img{
        margin: 0;
    }
    #videoDeveloped{
        font-size: 1.1em;
    }
    #videoDevelopedSubtitle{
        font-size: 0.7em;
        margin-bottom: 20px;
    }
    .videoGridKofu iframe{
        width: 300px;
        height: 150px;
    }
    #latestNewsTitle{
        font-size: 1.2em;
    }
    .finaldivKofu{
        height: 370px;
        margin-top: 4%;
        margin-bottom: 5%;
        padding-left: 5%;
        padding-right: 5%;
    }
}

@media only screen and (max-width: 720px) {
    #videoDeveloped{
        font-size: 1em;
    }
    #videoDevelopedSubtitle{
        font-size: 0.6em;
        margin-bottom: 10px;
    }
    .videoGridKofu iframe{
        width: 250px;
        height: 100px;
    }
}



@media only screen and (max-width: 445px) {
    .howBuilt{
        height: 500px;
    }
    .howBuilt p {
        font-size: 0.7em;
    }
    .videoGridKofu iframe{
        width: 300px;
        height: 150px;
    }
}