.footer{
    height: 220px;
    background-color: #25262B;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
}

.titles{
    font-size: 1.5em;
    font-weight: bold;
    padding-top: 5px;
}

.titles a{
    font-size: 1em;
    font-weight: bold;
}

.titles a:hover{
    color: #65F4AD;
}

.dot {
    height: 10px;
    width: 10px;
    background-color: white;
    border-radius: 50%;
    display: inline-block;
    margin-left: 30px;
    margin-right: 30px;
}

.bottomSection{
    display: flex;
    flex-direction: row;
    margin-top: 60px;
    font-size: 0.7em;
    align-items: center;
}

@media only screen and (max-width: 650px) {
    .footer{
        height: 190px;
    }
    .titles{
        font-size: 1em;
        padding-top: 5px;
    }
    .titles a{
        font-size: 1em;
    }
    .bottomSection{
        margin-top: 50px;
        font-size: 0.7em;
    }
}

@media only screen and (max-width: 420px) {
    .footer{
        height: 190px;
    }
    .titles{
        font-size: 0.9em;
        padding-top: 5px;
    }
    .titles a{
        font-size: 0.9em;
    }
    .bottomSection{
        margin-top: 30px;
        font-size: 0.7em;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 60px;
        align-items: center;
    }
}