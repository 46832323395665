#navbar {
  background: #2C2F33;
  color: white;
  position: fixed;
  top: 0;
  height: 60px;
  line-height: 60px;
  width: 100%;
  z-index: 10;
}

.nav-wrapper {
  margin: auto;
  text-align: center;
  width: 95%;
} @media(max-width: 768px) {
  .nav-wrapper {
    width: 90%;
  }
} @media(max-width: 638px) {
  .nav-wrapper {
    width: 100%;
  }
}

.mvlNav{
  display: none;
  background: #2C2F33;
  position: fixed;
  top: 0;
  height: 60px;
  line-height: 60px;
  width: 100%;
  z-index: 15 !important;
}

.mvlNavOpen{
  height: 100% !important;
}

.logo-name{
  font-family: 'Roboto Mono', monospace;
  font-weight: bolder;
  font-size: 1.2em;
}
.logo a {
  font-size: 1.5em;
  font-family: 'Roboto Mono Thin', monospace;
  transition-duration: 1s;
}

.logoMVL a {
  font-size: 1.5em;
  font-family: 'Roboto Mono Thin', monospace;
  transition-duration: 1s;
}

.logoMVL {
  display: none;
  height: 40px;
  position: absolute;
  top: 0;
  left: 10px;
  color: white;
}

.logoMVL:hover > a{
  color: #65f4ad;
}

.selected{
  color: #65F4AD;
}

.logo {
  height: 30px;
  float: left;
}

.logo:hover > a{
  color: #65f4ad;
}


#navbar ul {
  display: inline-block;
  float: right;
  list-style: none;
  margin-top: 3px;
  text-align: right;
  transition: transform 0.5s ease-out;
  -webkit-transition: transform 0.5s ease-out;
} @media(max-width: 640px) {
  #navbar ul {
    display: none;
  }
} @media(orientation: landscape) {
  #navbar ul {
    display: inline-block;
  }
}

#navbar li {
  display: inline-block;
}

#navbar li a {
  color: white;
  display: block;
  font-size: 1.0em;
  height: 50px;
  letter-spacing: 1px;
  margin: 0 20px;
  padding: 0 4px;
  position: relative;
  text-decoration: none;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

#navbar li a:hover {
  color: #65F4AD;
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
}

#navbar li a:before, #navbar li a:after {
  content: '';
  position: absolute;
  width: 0;
  height: 1px;
  bottom: -1px;
  background: #65F4AD;
}

#navbar li a:before {
  left: 10%;
  transition: 0.5s;
}

#navbar li a:after {
  background: #65F4AD;
  right: 10%;
}

#navbar li a:hover:before {
  background: #65F4AD;
  width: 80%;
  transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
}

#navbar li a:hover:after {
  background: transparent;
  width: 80%;
}


/**********************************
               PROVES
**********************************/

.main-nav {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  background: #2C2F33;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  transition: all .375s;
}

.main-nav.is-open {
  opacity: 1;
  z-index: 100;
  visibility: visible;
}

.main-nav::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: -15px;
  background: #25262B;
  transform-origin: 0 0;
  transform: skew(-14deg) translateX(-120%);
  transition: all .275s .1s;
}

.main-nav.is-open::before {
  transform: skew(-14deg) translateX(0);
}

.main-nav ul {
  display: inline-flex;
  flex-direction: column;
  height: 93%;
  align-items: flex-end;
  justify-content: center;
  transform: translateX(-18%) skew(-16deg);
}

.main-nav li {
  display: block;
  margin: .5rem 0;
  text-align: right;
  transform: skew(16deg);
}

.main-nav a {
  opacity: 0;
  transform: translateY(-10px);
}

.main-nav.is-open a {
  opacity: 1;
  transform: translateY(0);
}
.main-nav li:nth-child(1) a {
  transition: all 275ms 175ms
}
.main-nav li:nth-child(2) a {
  transition: all 275ms 225ms
}
.main-nav li:nth-child(3) a {
  transition: all 275ms 275ms
}
.main-nav li:nth-child(4) a {
  transition: all 275ms 325ms;
  margin-right: 40px !important;
}
.main-nav li:nth-child(5) a {
  transition: all 275ms 375ms
}

.main-nav ul,
.main-nav li {
  list-style: none;
  padding: 0;
}
.main-nav a {
  display: block;
  padding: 12px 0;
  color: #2C2F33;
  font-size: 1.6em;
  text-decoration: none;
  font-weight: bold;
}

.open-main-nav {
  display: none;
  position: absolute;
  top: 15px;
  padding-top: 20px;
  right: 15px;
  z-index: 1000;
  background: none;
  border: 0;
  cursor: pointer;
}

.open-main-nav:focus {
  outline: none;
}
.burger {
  position: relative;
  display: block;
  width: 28px;
  height: 4px;
  margin: 0 auto;
  background: white;
  transform: skew(5deg);
  transition: all .275s;
}

.burger:after,
.burger:before {
  content: '';
  display: block;
  height: 100%;
  background: white;
  transition: all .275s;
}

.burger:after {
  transform: translateY(-12px) translateX(-2px) skew(-20deg);
}

.burger:before {
  transform: translateY(-16px) skew(-10deg);
}

.is-open .burger {
  transform: skew(5deg) translateY(-8px) rotate(-45deg);
}

.is-open .burger:before {
  transform: translateY(0px) skew(-10deg) rotate(75deg);
}

.is-open .burger:after {
  transform: translateY(-12px) translateX(10px) skew(-20deg);
  opacity: 0;
}

.notice {
  position: absolute;
  bottom: -15px;
  left: 0; right: 0;
  padding: 20px;
  background: #F2F2F2;
  color: #5A3B5D;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  z-index: 100;
  text-align: center;
}
.notice strong {
  font-weight: 700;
}
.notice a {
  padding: 2px 3px;
  background: #FEDC2A;
  text-decoration: none;
}

#main-nav li a {
  color: white;
  display: block;
  font-size: 1.4em;
  height: 70px;
  letter-spacing: 1px;
  margin: 0 5px;
  padding: 0 4px;
  position: relative;
  text-decoration: none;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

#main-nav li a:hover {
  color: #65F4AD;
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
}

#main-nav li a:before, #main-nav li a:after {
  content: '';
  position: absolute;
  width: 0;
  height: 1px;
  bottom: 25px;
  background: #65F4AD;
}

#main-nav li a:before {
  left: 10%;
  transition: 0.5s;
}

#main-nav li a:after {
  background: #65F4AD;
  right: 10%;
}

#main-nav li a:hover:before {
  background: #65F4AD;
  width: 80%;
  transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
}

#navbar li a:hover:after {
  background: transparent;
  width: 80%;
}

@media only screen and (max-width: 1020px) {
  .logo-name {
    font-size: 1em;
  }
  .logo a {
    font-size: 1.3em;
  }
  #navbar li a {
    font-size: 0.8em;
  }
  #navbar ul {
    margin-top: 0;
  }
}


@media only screen and (max-width: 865px) {
  .open-main-nav {
    display: block;
  }
  .mvlNav{
    display: block;
  }
  .logoMVL {
    display: block;
  }
  .nav-wrapper {
    display: none;
  }
  #navbar {
    display: none;
  }
}



